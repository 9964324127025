<template>
  <div>
    <div class="d-flex justify-content-start text-center my-4">
        <button onclick="window.print();" type="button" class="d-print-none btn btn-info d-block">Imprimir</button>
    </div>
    <div class="" style="width:100mm;height:150mm;">
      <div class="card shadow-sm border">
        <div class="card-body p-3">
          <div class="row">
            <div class="col-12 font-weight-bold h4 mb-2 text-center text-capitalize">
              <span v-if="record.client && record.client.type_id === 2">
                {{ record.client.commercial_name }}
              </span>
              <span v-if="record.client && record.client.type_id === 1">
                {{ record.client.first_name }} {{ record.client.last_name  }}
              </span>
            </div>
            <div class="col-12 mb-2 text-center">
              <barcode
                :value="record.id"
                :options="{ flat: true, margin: 0, width: 3, height: 45, displayValue: false }"
                v-if="record.id"
              >
                Códgo inválido
              </barcode>
            </div>
            <div class="col-12 mb-2 font-weight-bold h5 mb-2 text-center text-capitalize">
              {{ record.against_to | uppercase }}
            </div>
            <div class="col-12 mb-2 font-weight-bold h5 mb-2 text-center text-capitalize">
              {{ record.name | uppercase }}
            </div>
            <div class="col-4 text-center font-weight-bold ">
              A {{ record.file_container }}
            </div>
            <div class="col-4 text-center font-weight-bold ">
              F {{ record.file_column }}
            </div>
            <div class="col-4 text-center font-weight-bold ">
              C {{ record.file_row }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Barcode from '@chenfengyuan/vue-barcode'

export default {
  name: 'Label',
  props: ['id'],
  data () {
    return {
      record: {}
    }
  },
  methods: {
    ...mapActions({
      fetchRecord: 'Record/fetchRecord'
    })
  },
  mounted () {
    this.fetchRecord({
      id: this.id
    })
      .then(response => {
        this.record = response.data
      })
  },
  beforeDestroy () {
    this.$events.off('table-item-edit')
  },
  components: {
    Barcode
  }
}
</script>
